import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { Title } from "../styles";

const PageIntroduction = ({ title, body, noHeaderSpacing }) => {
  return (
    <Row>
      <Col>
        <IntroductionText noHeaderSpacing={noHeaderSpacing}>
          <Title>{title}</Title>
          <Body>{body}</Body>
        </IntroductionText>
      </Col>
    </Row>
  );
};

const IntroductionText = styled.div`
  margin-top: ${(props) => (props.noHeaderSpacing ? "0" : "130px")};
  margin-bottom: 3rem;
`;

const Body = styled.p`
  @media (min-width: 992px) {
    text-align: center;
    margin: 0 5rem;
  }
`;

export default PageIntroduction;
