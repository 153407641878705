import styled from "styled-components";

const Heading = styled.h2`
  color: ${({ theme: { colors } }) => colors.purple};
  margin-top: 1rem;
  font-weight: 600;

  @media (min-width: 992px) {
    text-align: center;
  }
`;

const Title = styled(Heading)`
  text-align: center;
  margin: 3rem 0;
`;

const staticImageFullHeightStyle = {
  height: "100%",
};

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Button = styled.a`
  background-color: ${({ theme: { colors } }) => colors.green};
  border: solid 4px ${({ theme: { colors } }) => colors.purple};
  display: flex;
  justify-content: center;
  padding: 1rem;
  margin: 2rem 0;
  color: black;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.md};
  border-radius: 50px;
  color: ${({ theme: { colors } }) => colors.purpleSecondary};
  text-decoration: none;
  width: 100%;
  a {
    color: ${({ theme: { colors } }) => colors.purpleSecondary};
    text-decoration: none;
  }

  @media (min-width: 992px) {
    width: 300px;
    &:hover {
      text-decoration: none;
      color: ${({ theme: { colors } }) => colors.purple};
    }
  }
`;

export { Heading, Title, staticImageFullHeightStyle, ButtonWrapper, Button };
